*{
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
 }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* MavBar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #332A2B; /* Set the background color to a dark color */
  color: #fff; /* Set the text color to white */
  padding: 10px;
}
.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Add any other styles you need for the navbar-logo container */
}
.navbar-logo img {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.navbar-logo span {
  font-size: 18px;
  font-weight: bold;
}

.navbar-links {
  list-style: none;
    padding: 0;
    margin: 0;
}

.navbar-links li {
  display: inline-block;
  position: relative;
  padding-right: 20px;
  margin-right: 10px;
}

  .navbar-links li:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 50%;
    background-color: #ffffff; /* You can adjust the color as needed */
    transform: translateY(-50%);
  }


.navbar-links a {
  text-decoration: none;
  color: #fff; /* Set the link color to white */
}

.navbar-button {
  text-decoration: none;
  padding: 10px 20px;
  background-color: #570C0C;
  color: #fff;
  border: 2px solid #B43C24;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Adding transitions for smooth effects */
}

.navbar-button:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
  transform: scale(1.1); /* Scale the button to 110% on hover */
}

/* App.js */
.App-banner {
  width: 100%;
  max-height: auto; /* Adjust the maximum height of the banner as needed */
  object-fit: cover; /* Scale the image to cover the entire container */
}


